<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page() {
        return {
            title: this.title,
            meta: [{ name: "description" }],
        };
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Starter page",
            items: [
                {
                    text: "Utility",
                    href: "/",
                },
                {
                    text: "Starter page",
                    active: true,
                },
            ],
            loading: false,
            userinfo: [],
            micoapplist: [],
            micoappmenu: [],
            defaultmenu: [
                {
                    id: "address",
                    name: "收货地址",
                    icon: "shouhuodizhi",
                    type: "link",
                    url: "pages/address/index",
                    selected: false
                },
                {
                    id: "card",
                    name: "我的卡片",
                    icon: "artboard",
                    type: "link",
                    url: "pages/wallet/card",
                    selected: false
                },
                {
                    id: "coupon",
                    name: "领券中心",
                    icon: "lingquan",
                    type: "link",
                    url: "pages/coupon/index",
                    selected: false
                },
                {
                    id: "myCoupon",
                    name: "优惠券",
                    icon: "youhuiquan",
                    type: "link",
                    url: "pages/my-coupon/index",
                    selected: false
                },
                {
                    id: "help",
                    name: "我的帮助",
                    icon: "bangzhu",
                    type: "link",
                    url: "pages/help/index",
                    selected: false
                },
                {
                    id: "contact",
                    name: "在线客服",
                    icon: "kefu",
                    type: "tap",
                    openType: "contact",
                    selected: false
                },
                {
                    id: "points",
                    name: "我的积分",
                    icon: "jifen",
                    type: "link",
                    url: "pages/points/log",
                    selected: false
                },
                {
                    id: "refund",
                    name: "退换/售后",
                    icon: "shouhou",
                    type: "link",
                    url: "pages/refund/index",
                    count: 0,
                    selected: false
                },
                {
                    id: "qrcode",
                    name: "付款码",
                    icon: "erweima",
                    type: "link",
                    url: "pages/wallet/paymentqr",
                    selected: false
                },
                {
                    id: "gatecode",
                    name: "门禁码",
                    icon: "erweima",
                    type: "link",
                    url: "pages/wallet/gateqr",
                    selected: false
                }
            ],
            navbar: [],
            defaultnavbar: [
                {
                    id: "all",
                    name: "全部订单",
                    icon: "qpdingdan",
                    selected: false
                },
                {
                    id: "payment",
                    name: "待支付",
                    icon: "daifukuan",
                    count: 0,
                    selected: false
                },
                {
                    id: "delivery",
                    name: "待发货",
                    icon: "daifahuo",
                    count: 0,
                    selected: false
                },
                {
                    id: "received",
                    name: "待收货",
                    icon: "daishouhuo",
                    count: 0,
                    selected: false
                }
            ]
        };
    },
    mounted() {
        this.userinfo = JSON.parse(localStorage.getItem("user"));
        this.appid = this.$route.params.appid;
        this.title = "小程序菜单";
        this.items = [
            {
                text: this.$t("menuitems.micoapp.text"),
                href: "/",
            },
            {
                text: this.$t("menuitems.icp.micoapp.list"),
                href: "/micoapp",
            },
            {
                text: this.$t("menuitems.icp.micoapp.info"),
                href: `/micoapp/${this.appid}`,
            },
            {
                text: "小程序菜单",
                active: true,
            },
        ];
        this.getmicoappmenu();
    },
    methods: {
        getmicoappmenu() {
            var that = this;
            that.loading = true;
            that.$axios
                .post(
                    "https://api.aicoiot.com/micoappapi.php",
                    {
                        action: "micoapp.menu",
                        appid: that.appid,
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    that.micoappmenu = JSON.parse(response.data.data.menu);
                    that.navbar = JSON.parse(response.data.data.navbar);
                })
                .catch(function (error) {
                    that.loading = false;
                    that.$notify.error({
                        title: '请求错误',
                        message: error
                    });
                });
        },
        updateMenuItem(updatedItem) {
            const index = this.defaultmenu.findIndex(item => item.id === updatedItem.id);
            if (index !== -1) {
                this.$set(this.defaultmenu, index, updatedItem);
            }
        },
        updateMicoAppNavbar() {

        },
        updateMicoAppMenu() {

        }
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" v-loading="loading">
                        <el-empty description="功能暂未上线"></el-empty>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>